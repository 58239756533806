<template>
    <div>
        <company-route-tabs
            v-if="isSuperAdmin"
            :company-id="companyId"
        ></company-route-tabs>
        <company-allowance-form
            :company-id="companyId"
        ></company-allowance-form>
    </div>
</template>

<script>
import CompanyAllowanceForm from '@/components/pages/companyAllowances/Form'
import CompanyRouteTabs from '@/components/pages/companies/RouteTabs'
import dirtyForm from '@/components/mixins/dirtyForm'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: { CompanyAllowanceForm, CompanyRouteTabs },
    mixins: [dirtyForm, AuthMixin],

    props: {
        companyId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
