var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":{ company: { exact: _vm.company } }},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-select":"","row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.intervals",fn:function(ref){
var item = ref.item;
return _vm._l((item.days),function(day){return _c('v-chip',{key:day.day,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$t('days_of_week.' + (day.day - 1)))+" ")])})}},{key:"item.intervalTimeUnit",fn:function(ref){
var item = ref.item;
return [(item.intervalTimePiece % 7 == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc( 'labels.interval_week', item.intervalTimePiece / 7, { n: item.intervalTimePiece / 7 } ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$tc( 'labels.interval_' + item.intervalTimeUnit, item.intervalTimePiece, { n: item.intervalTimePiece } ))+" ")])]}},{key:"item.budgetLimit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.budgetLimit))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(_vm.showActivateCheckboxes)?_c('checkbox-input',{attrs:{"value":item.isActive},on:{"input":function($event){item.isActive
                                ? _vm.confirmDeactivation(item)
                                : _vm.confirmActivation(item)}}}):_vm._e()]}},{key:"rowActions",fn:function(ref){
                                var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}},{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}],null,true)}),_c('confirm-modal',{attrs:{"value":_vm.confirmActivationModal,"text":_vm.$t('texts.confirm_allowance_activation', {
                restaurant: _vm.itemSelectedForActivation
                    ? _vm.itemSelectedForActivation.name
                    : '',
            }),"processing":_vm.processingActivation},on:{"onApprove":_vm.activateEntity,"onCancel":_vm.resetActiveCheckboxes}}),_c('confirm-modal',{attrs:{"value":_vm.confirmDeactivationModal,"text":_vm.$t('texts.confirm_allowance_deactivation', {
                restaurant: _vm.itemSelectedForActivation
                    ? _vm.itemSelectedForActivation.name
                    : '',
            }),"processing":_vm.processingActivation},on:{"onApprove":_vm.deactivateEntity,"onCancel":_vm.resetActiveCheckboxes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }