<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <v-row justify="center">
                <v-expansion-panels v-model="activeTab" accordion>
                    <!-- general settings -->
                    <v-expansion-panel>
                        <form-expansion-header
                            :tab-errors="tabErrors"
                            name="general"
                            :title="$t('labels.general_settings')"
                        ></form-expansion-header>
                        <v-expansion-panel-content>
                            <form-panel>
                                <form-two-col-row>
                                    <template slot="col1">
                                        <text-input
                                            v-model="form.uniqueId"
                                            v-validate="
                                                'required|max:255|space'
                                            "
                                            v-tab-error:general
                                            name="uniqueId"
                                            label="labels.unique_id"
                                            :error-messages="
                                                errors.collect('uniqueId')
                                            "
                                            :data-vv-as="$t('labels.unique_id')"
                                            autofocus
                                        ></text-input>
                                    </template>
                                    <template slot="col2">
                                        <text-input
                                            v-model="form.name"
                                            v-validate="
                                                'required|max:255|space'
                                            "
                                            v-tab-error:general
                                            name="name"
                                            label="labels.name"
                                            :error-messages="
                                                errors.collect('name')
                                            "
                                            :data-vv-as="$t('labels.name')"
                                        ></text-input>
                                    </template>
                                </form-two-col-row>
                                <form-two-col-row>
                                    <template slot="col1">
                                        <checkbox-input
                                            v-model="form.isActive"
                                            label="labels.active"
                                            name="isActive"
                                        ></checkbox-input>
                                    </template>
                                    <template slot="col2">
                                        <textarea-input
                                            v-model="form.description"
                                            v-validate="'space'"
                                            v-tab-error:general
                                            name="description"
                                            label="labels.description"
                                            :error-messages="
                                                errors.collect('description')
                                            "
                                            :data-vv-as="
                                                $t('labels.description')
                                            "
                                        ></textarea-input>
                                    </template>
                                </form-two-col-row>
                                <form-two-col-row>
                                    <template v-slot:col1>
                                        <entity-select-input
                                            v-model="form.companyTeams"
                                            v-validate=""
                                            v-tab-error:general
                                            :entity="
                                                selectApiClientType.COMPANY_TEAMS
                                            "
                                            :query-params="{
                                                companyId,
                                            }"
                                            multi-select
                                            :disabled="employeesCount > 0"
                                            label="labels.teams"
                                            name="teams"
                                            :error-messages="
                                                errors.collect('teams')
                                            "
                                            :data-vv-as="$t('labels.teams')"
                                        ></entity-select-input>
                                        <div
                                            v-show="employeesCount > 0"
                                            class="g-b2--text"
                                        >
                                            {{
                                                $t(
                                                    'texts.cannot_assign_teams',
                                                    {
                                                        count: employeesCount,
                                                    }
                                                )
                                            }}
                                        </div>
                                    </template>
                                </form-two-col-row>
                            </form-panel>

                            <!-- budget -->
                            <form-panel :title="$t('labels.budget')">
                                <form-two-col-row>
                                    <template slot="col1">
                                        <entity-select-input
                                            v-model="form.balanceType"
                                            v-validate="'required'"
                                            v-tab-error:general
                                            :entity="
                                                selectApiClientType.COMPANY_ALLOWANCE_BALANCE_TYPES
                                            "
                                            label="labels.type"
                                            name="balanceType"
                                            :error-messages="
                                                errors.collect('balanceType')
                                            "
                                            :data-vv-as="$t('labels.type')"
                                            translate-items
                                        ></entity-select-input>
                                    </template>
                                    <template slot="col2">
                                        <currency-input
                                            v-model="form.budgetLimit"
                                            v-validate="
                                                'required|min_value:0|decimal:2'
                                            "
                                            v-tab-error:general
                                            name="budgetLimit"
                                            label="labels.budget"
                                            :error-messages="
                                                errors.collect('budgetLimit')
                                            "
                                            :data-vv-as="$t('labels.budget')"
                                        ></currency-input>
                                    </template>
                                </form-two-col-row>
                                <form-three-col-row>
                                    <template slot="col1">
                                        <entity-select-input
                                            v-model="form.intervalTimeUnit"
                                            v-validate="'required'"
                                            v-tab-error:general
                                            :entity="
                                                selectApiClientType.COMPANY_ALLOWANCE_TIME_UNITS
                                            "
                                            label="labels.regularity"
                                            name="intervalTimeUnit"
                                            :error-messages="
                                                errors.collect(
                                                    'intervalTimeUnit'
                                                )
                                            "
                                            :data-vv-as="
                                                $t('labels.regularity')
                                            "
                                            translate-items
                                        ></entity-select-input>
                                    </template>
                                    <template slot="col2">
                                        <text-input
                                            v-model.number="
                                                form.intervalTimePiece
                                            "
                                            v-validate="
                                                'required|min_value:0|decimal:0'
                                            "
                                            v-tab-error:general
                                            name="intervalTimePiece"
                                            label="labels.interval_quantity"
                                            :error-messages="
                                                errors.collect(
                                                    'intervalTimePiece'
                                                )
                                            "
                                            :data-vv-as="
                                                $t('labels.interval_quantity')
                                            "
                                            type="number"
                                        ></text-input>
                                    </template>
                                    <template v-slot:col3>
                                        <text-input
                                            v-model.number="form.usageLimit"
                                            v-validate="
                                                'required|min_value:0|decimal:0'
                                            "
                                            v-tab-error:general
                                            name="usageLimit"
                                            label="labels.usage_limit"
                                            :error-messages="
                                                errors.collect('usageLimit')
                                            "
                                            :data-vv-as="
                                                $t('labels.usage_limit')
                                            "
                                            :hint="$t('texts.usage_limit_info')"
                                            type="number"
                                        ></text-input>
                                    </template>
                                </form-three-col-row>
                                <form-two-col-row>
                                    <template slot="col1">
                                        <entity-select-input
                                            v-model="form.addressRestriction"
                                            v-validate="'required'"
                                            v-tab-error:general
                                            type="checkbox"
                                            :entity="
                                                selectApiClientType.COMPANY_ALLOWANCE_ADDRESS_RESTRICTIONS
                                            "
                                            label="labels.allowance_address_restriction"
                                            name="addressRestriction"
                                            :error-messages="
                                                errors.collect(
                                                    'addressRestriction'
                                                )
                                            "
                                            :data-vv-as="
                                                $t(
                                                    'labels.allowance_address_restriction'
                                                )
                                            "
                                            translate-items
                                        ></entity-select-input>
                                    </template>
                                    <template slot="col2">
                                        <checkbox-input
                                            v-model="
                                                form.timeSettingsDontApplyToNonCompanyAddresses
                                            "
                                            label="texts.time_settings_dont_apply_to_non_company_addresses"
                                            name="timeSettingsDontApplyToNonCompanyAddresses"
                                        ></checkbox-input>
                                        <checkbox-input
                                            v-model="form.useJointBalance"
                                            label="labels.joint_balance"
                                            name="useJointBalance"
                                            :disabled="jointBalanceDisabled"
                                        ></checkbox-input>
                                    </template>
                                </form-two-col-row>
                            </form-panel>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- period -->
                    <v-expansion-panel>
                        <form-expansion-header
                            :tab-errors="tabErrors"
                            name="periods"
                            :title="$t('labels.period')"
                        ></form-expansion-header>
                        <v-expansion-panel-content>
                            <form-panel>
                                <form-two-col-row>
                                    <template slot="col1">
                                        <date-picker-input
                                            v-model="form.intervalStart"
                                            v-validate="'required'"
                                            v-tab-error:periods
                                            label="labels.start_date"
                                            name="intervalStart"
                                            only-date
                                            :error-messages="
                                                errors.collect('intervalStart')
                                            "
                                            :data-vv-as="
                                                $t('labels.start_date')
                                            "
                                        ></date-picker-input>
                                    </template>
                                </form-two-col-row>
                                <form-one-col-row>
                                    <period-input
                                        v-model="form.days"
                                        name="days"
                                        can-edit
                                    ></period-input>
                                </form-one-col-row>
                            </form-panel>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- expense code -->
                    <v-expansion-panel>
                        <form-expansion-header
                            :tab-errors="tabErrors"
                            name="expenseCode"
                            :title="$t('labels.expense_code')"
                        ></form-expansion-header>
                        <v-expansion-panel-content>
                            <form-panel>
                                <form-one-col-row extra-space>
                                    <tag-input
                                        v-model="form.expenseCodeList"
                                        label="labels.expense_code_list"
                                    ></tag-input>
                                    <p class="font-italic">
                                        {{ $t('labels.combobox_hint') }}
                                    </p>
                                </form-one-col-row>
                                <form-two-col-row>
                                    <template slot="col1">
                                        <select-input
                                            v-model="form.expenseCodeRequired"
                                            name="expenseCodeRequired"
                                            :items="expenseCodeRequired"
                                            label="labels.expense_code_settings"
                                        ></select-input>
                                    </template>
                                    <template slot="col2">
                                        <checkbox-input
                                            v-model="
                                                form.expenseCodeOnlyFromList
                                            "
                                            label="texts.employee_can_only_input_expense_code_from_list"
                                            name="timeSettingsDontApplyToNonCompanyAddresses"
                                        ></checkbox-input>
                                    </template>
                                </form-two-col-row>
                            </form-panel>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- group order -->
                    <v-expansion-panel v-if="!form.useJointBalance">
                        <form-expansion-header
                            :tab-errors="tabErrors"
                            name="groupOrder"
                            :title="$t('labels.group_order')"
                        ></form-expansion-header>
                        <v-expansion-panel-content>
                            <form-panel>
                                <form-two-col-row extra-space>
                                    <template slot="col1">
                                        <checkbox-input
                                            v-model="form.canGroupOrder"
                                            label="texts.is_group_order_available"
                                            name="canGroupOrder"
                                        ></checkbox-input>
                                    </template>
                                </form-two-col-row>
                                <form-one-col-row>
                                    <entity-select-input
                                        v-model="
                                            form.groupAllowedEmployeesExcept
                                        "
                                        v-validate="{}"
                                        v-tab-error:groupOrder
                                        :entity="selectApiClientType.USER"
                                        label="labels.employees_allowed_in_group_order"
                                        name="groupAllowedEmployeesExcept"
                                        :error-messages="
                                            errors.collect(
                                                'groupAllowedEmployeesExcept'
                                            )
                                        "
                                        :data-vv-as="
                                            $t(
                                                'labels.employees_allowed_in_group_order'
                                            )
                                        "
                                        :query-params="{
                                            company: companyApiClient.getItemIri(
                                                companyId
                                            ),
                                            allowance: form['@id'],
                                        }"
                                        :disabled="!form.canGroupOrder"
                                        autocomplete
                                        multi-select
                                        reverse
                                    ></entity-select-input>
                                </form-one-col-row>
                            </form-panel>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- joint balances -->
                    <v-expansion-panel v-else-if="!isCreate && isSuperAdmin">
                        <form-expansion-header
                            :tab-errors="tabErrors"
                            name="jointBalances"
                            :title="$t('labels.balances')"
                        ></form-expansion-header>
                        <v-expansion-panel-content>
                            <joint-balance-table
                                :allowance-iri="form['@id']"
                            ></joint-balance-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import ApiClientType from '@/api/RestApiType'
import configType from '@/store/type/configType'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import FormBase from '@/components/mixins/FormBase'
import ActiveTabMixin from '@/components/mixins/ActiveTabMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import AuthMixin from '@/components/mixins/AuthMixin'
import FormExpansionHeader from '@/components/form/FormExpansionHeader'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormThreeColRow from '@/components/form/FormThreeColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import SelectInput from '@/components/form/Inputs/SelectInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import TagInput from '@/components/form/Inputs/TagInput'
import PeriodInput from '@/components/form/Inputs/PeriodInput'
import JointBalanceTable from './JointBalanceTable'

export default {
    components: {
        FormExpansionHeader,
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        FormThreeColRow,
        TextInput,
        TextareaInput,
        CurrencyInput,
        DatePickerInput,
        EntitySelectInput,
        SelectInput,
        CheckboxInput,
        TagInput,
        PeriodInput,
        JointBalanceTable,
    },
    mixins: [FormBase, ActiveTabMixin, FormTabMixin, AuthMixin],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: ApiClientType.COMPANY_ALLOWANCES,
            mapper: MapperType.COMPANY_ALLOWANCES_FORM,
            form: {
                uniqueId: null,
                name: null,
                description: null,
                budgetLimit: null,
                balanceType: null,
                intervalTimeUnit: null,
                intervalTimePiece: 1,
                addressRestriction: 'none',
                timeSettingsDontApplyToNonCompanyAddresses: false,
                canGroupOrder: false,
                groupAllowedEmployeesExcept: [],
                isActive: true,
                expenseCodeOnlyFromList: false,
                expenseCodeList: [],
                expenseCodeRequired: null,
                days: [],
                intervalStart: null,
                companyTeams: [],
                useJointBalance: false,
                usageLimit: 0,
            },
            entity: 'labels.company_allowances',
            editRoute: routeType.COMPANY_ALLOWANCE_EDIT,
            listRoute: routeType.COMPANY_ALLOWANCE_LIST,
            companyApiClient: RestApiCollection.get(ApiClientType.COMPANIES),
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
            currencyLocale: configType.getters.CURRENCY_LOCALE,
        }),
        expenseCodeRequired() {
            return [
                {
                    title: this.$t('labels.not_required'),
                    value: null,
                },
                {
                    title: this.$t('labels.optional'),
                    value: 1,
                },
                {
                    title: this.$t('labels.required'),
                    value: 2,
                },
            ]
        },
        employeesCount() {
            return this.form?.employeesCount || 0
        },
        isCreate() {
            return !this.form?.['@id']
        },
        jointBalanceDisabled() {
            return !this.isCreate
        },
    },
    created() {
        this.form.currencyCode = this.currencyCode
        this.form.locale = this.currencyLocale
        this.form.company = this.companyApiClient.getItemIri(this.companyId)
    },
    methods: {
        editRedirectParams(data) {
            return {
                allowanceId: data.id,
                companyId: this.companyId,
            }
        },
        listRedirectParams() {
            return { companyId: this.companyId }
        },
    },
}
</script>
