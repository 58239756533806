export default {
    computed: {
        headers() {
            return [
                {
                    value: 'uniqueId',
                    text: this.$t('labels.unique_id'),
                    type: 'string',
                },
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'intervals',
                    text: this.$t('labels.days'),
                    sortable: false,
                    class: 'cell-days',
                },
                {
                    value: 'budgetLimit',
                    text: this.$t('labels.budget'),
                },
                {
                    value: 'intervalTimeUnit',
                    text: this.$t('labels.interval'),
                },
                {
                    value: 'active',
                    text: this.$t('labels.active'),
                },
            ]
        },
    },
}
